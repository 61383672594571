import React from "react"
import ReactDOM from "react-dom"

import L from "leaflet"
import { withLeaflet, MapControl } from "react-leaflet"

class MapAddressControl extends MapControl {

  createLeafletElement(props) {
    const control = L.control({position: "topleft"})
    const jsx = (
      <div className={"leaflet-control-address"}>
        <strong>{props.title}</strong><br/>
        <span>{props.subtitle}</span>
      </div>
    )
    control.onAdd = () => {
      const div = L.DomUtil.create('div', '')
      ReactDOM.render(jsx, div)
      return div
    }
    return control
  }

  componentDidMount() {
    const { map } = this.props.leaflet
    this.leafletElement.addTo(map)

    L.DomEvent
      .disableClickPropagation(this.leafletElement.getContainer())
      .disableScrollPropagation(this.leafletElement.getContainer())
  }
}

export default withLeaflet(MapAddressControl)
