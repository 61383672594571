import React from "react"

import PropTypes from "prop-types"
import {Map as LMap, TileLayer, ZoomControl} from "react-leaflet"

import MapAddressControl from "./control"

const Map = (props) => {

  if(typeof window !== "undefined") {

    return (
      <LMap
        center={props.position}
        zoom={18}
        zoomControl={false}
      >
        <ZoomControl position={"topright"} />
        <TileLayer
          url={"https://api.mapbox.com/styles/v1/" +
          "redoakconsulting/" +
          "ckevncqyh0qc719ntgoax4x5w/" +
          "tiles/256/{z}/{x}/{y}@2x?access_token=" +
          "pk.eyJ1IjoicmVkb2FrY29uc3VsdGluZyIsImEiOiJja2V2a3pnY2gxMGtj" +
          "MzNwN3BzdnBhbDR2In0.Mt5Tqjz70G5O5uhtqevsQQ"}
          attribution={'Map data &copy; ' +
          '<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
          }
        />
        <MapAddressControl
          position={"topleft"}
          title={props.title}
          subtitle={props.subtitle}
          url={props.url}
        />
      </LMap>
    )
  }

  return null
}

Map.propTypes = {
  position: PropTypes.arrayOf(
    PropTypes.number
  ),
  key: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  url: PropTypes.string,
}

export default Map