import React from "react"

import {graphql} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import HeaderSection from "../../components/header"
import Section from "../../components/section"
import Container from "../../components/container"
import Box from "../../components/box"
import Map from "../../components/map"
import Contact from "../../components/contact"

const ContactPage = ({_path, data}) => {
  return (
    <Layout path={"/kontakt"}>
      <SEO title="Kontakt"/>
      <HeaderSection
        type={"image-fullscreen"}
        title={"Kontakt"}
        image={data.headerImage.childImageSharp}
      />
      <CallToActionSection
        image={data.redoakImage.childImageSharp}
      />
      <BraunschweigMapSection/>
      <OldenburgMapSection/>
    </Layout>
  )
}

const CallToActionSection = ({image}) => (
  <Section id={"CallToActionSection"} appear={"fromRight"}>
    <Container>
      <Box size={"2-to-3"}>
        <Contact
          imageFluid={image.fluid}
          title={"Red Oak Consulting"}
          street={"Westbahnhof 11"}
          city={"38118 Braunschweig"}
          mail={"kontakt@red-oak-consulting.com"}
          buttonType={"mail"}
          buttonUrl={"kontakt@red-oak-consulting.com"}
          buttonText={"Kontakt aufnehmen"}
        />
      </Box>
    </Container>
  </Section>
)

const BraunschweigMapSection = () => {

  return (
    <Section id={"BraunschweigMapSection"}>
      <Container justify={"left"} appear={"fromRight"}>
        <Box padding={"horizontal"}>
          <h2>Braunschweig</h2>
          <Map
            key={12345}
            position={[52.252837, 10.500444]}
            title={"Red Oak Consulting & Services for IT"}
            subtitle={"Westbahnhof 11, 38118 Braunschweig"}
            url={"https://api.mapbox.com/styles/v1/redoakconsulting/" +
            "ckevncqyh0qc719ntgoax4x5w.html?fresh=true&title=view" +
            "&access_token=pk.eyJ1IjoicmVkb2FrY29uc3VsdGluZyIsImEiOiJja2V2a3" +
            "pnY2gxMGtjMzNwN3BzdnBhbDR2In0.Mt5Tqjz70G5O5uhtqevsQQ" +
            "#16.86/52.252747/10.500477"
            }
          />
        </Box>
      </Container>
    </Section>
  )
}

const OldenburgMapSection = () => {

  return (
    <Section id={"OldenburgMapSection"}>
      <Container justify={"left"} appear={"fromLeft"}>
        <Box padding={"horizontal"}>
          <h2>Oldenburg</h2>
          <Map
            key={54321}
            position={[53.142839, 8.21348]}
            title={"Red Oak Consulting & Services for IT"}
            subtitle={"Lange Straße 6, 26122 Oldenburg"}
            url={"https://api.mapbox.com/styles/v1/redoakconsulting/" +
            "ckevncqyh0qc719ntgoax4x5w.html?fresh=true&title=view" +
            "&access_token=pk.eyJ1IjoicmVkb2FrY29uc3VsdGluZyIsImEiOiJja2V2a3" +
            "pnY2gxMGtjMzNwN3BzdnBhbDR2In0.Mt5Tqjz70G5O5uhtqevsQQ" +
            "#17.78/53.142875/8.213655"
            }
          />
        </Box>
      </Container>
    </Section>
  )
}

export default ContactPage

export const query = graphql`
    query ContactQuery {
        site {
            siteMetadata {
                title
            }
        }
        
        headerImage: file(relativePath: {eq: "contact/header.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        
        redoakImage: file(relativePath: {eq: "logo.png"}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`